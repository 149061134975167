import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface MainButtonProps {
  bg: string;
  text: string;
  src: string;
}

export const MainButton: React.FC<MainButtonProps> = ({ src, bg, text }) => {
  return (
    <Link to={src}>
      <Box>
        <Button
          size="md"
          height="48px"
          width="200px"
          bg={bg}
          fontFamily={"SUIT"}
          fontWeight={'bold'}
          color="black"
          _hover={{ opacity: 0.8 }}
        >
          {text}
        </Button>
      </Box>
    </Link>
  );
};

const buttonProps = [
  { bg: "#36E579", text: "제작문의", src: '/Contact' },
  { bg: "gray.50", text: "제휴문의", src: '/Contact' },
  // 추가 버튼 프롭스
];
// interface ButtonContainerProps {
//   buttons: MainButtonProps[]; // buttons는 MainButtonProps 타입의 배열입니다.
// }

export const ButtonContainer: React.FC = () => {
  return (
    <Flex justifyContent="center">
      <Flex justifyContent="center" gap={4} pt={24} direction={{ base: 'column', md: 'row' }}>
        {buttonProps.map((button, index) => (
          <MainButton key={index} bg={button.bg} text={button.text} src={button.src} />
        ))}
      </Flex>
    </Flex>
  );
};