import { MainButton } from '../../components/MainButton';
import ScrollDownIndicator from '../../components/ScrolledDownIndicator';
import { Box, chakra, Flex,Image } from '@chakra-ui/react';
import React from 'react';

const buttonProps = [
    {
        bg: '#01E96B',
        src: '/Xr/Ar',
        text: 'AR'
    },
    {
        bg: 'gray.100',
        src: '/Xr/Vr',
        text: 'VR'
    }
]

export default function XR() {
    return (
        <Box>
            <Box w={'atuo'} height={"100vh"} position={"relative"} overflow={"hidden"} justifyContent={'center'} alignItems={'center'} display='flex' flexDirection={"column"}>
                <Box width={{ base: 'full', sm: 'lg', lg: '3xl' }} display="flex" flexDirection="column" >

                    <chakra.h3
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        fontSize={20}
                        pt={24}
                        textTransform={'uppercase'}
                        textAlign={'center'}
                        color={'#01E96B'}>
                        Connect reality and virtuality
                    </chakra.h3>
                    <chakra.h1
                        py={5}
                        fontSize={48}
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        color="gray.300">
                        가상과 현실을 연결하는
                    </chakra.h1>
                    <chakra.h2
                        margin={'auto'}
                        width={'70%'}
                        fontFamily={'Inter'}
                        textAlign={'center'}
                        fontWeight={'medium'}
                        color='gray.400'>
                        의뢰기업은 자신의 공간을 VR/AR 기술로 실감나게 구현하여 가상공간 투어 제작할 수 있고
                        참여자는 가상공간(메타버스) 내에서 이루어지는 가상공간 투어를 통해 공간을 이해하고 즐길 수 있습니다.
                    </chakra.h2>
                    <Flex justifyContent="center" gap={4} pt={6}>
                        {buttonProps.map((button, index) => (
                            <MainButton key={index} bg={button.bg} text={button.text} src={button.src} />
                        ))}
                    </Flex>
                    {/* 스크롤 가이더 */}
                    <ScrollDownIndicator />
                </Box>

                {/*영상 관련부분  */}
                <Box position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
                    zIndex={'-2'}></Box>
                <Image
                    src='/2150898625.jpg'
                    alt="image"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: '-3'
                    }}
                />
            </Box>
        </Box >
    );
}
