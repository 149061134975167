import ScrollDownIndicator from '../../components/ScrolledDownIndicator';
import { Box, Flex, Image, VStack } from '@chakra-ui/react';
import React from 'react';
import { useEffect, useState } from "react";
// import { motion, useAnimation } from "framer-motion";
import { MyCardList } from '../../components/Card';
import SosokSticky from '../../components/sosok_Sticky';
import { ButtonContainer } from '../../components/MainButton';
// import { Stats } from 'fs';
import BannerSection from '../../components/BannerSeciton';
// import OpacitySection from '../../components/OpacitySection';
import StickySection from '../../components/StickySection';
import SlidingText from '../BandingAnimation';


export const Contents = () => {
  const [scrollY, setScrollY] = useState(0);
  // const [vh, setVh] = useState(0);


  // const getOpacity = (id: any) => {
  //   const distanceFromTop = id * 100 - scrollY; // id는 1부터 시작하므로 (id-1)*100vh가 해당 슬로건의 top 위치입니다.
  //   if (distanceFromTop >= 0 && distanceFromTop <= 100) {
  //     return 1 - distanceFromTop / 100;
  //   } else if (distanceFromTop > 100 && distanceFromTop <= 200) {
  //     return (distanceFromTop - 100) / 100;
  //   }
  //   return 0;
  // };

  useEffect(() => {
    // setVh(window.innerHeight);

    const handleScroll = () => {
      setScrollY(window.scrollY / window.innerHeight * 100); // vh 단위로 변환
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

    };
  }, [scrollY]);

  // OpacitySection map
  // const texts = [
  //   { mytext: '안녕하세요' },
  //   { mytext: '이호영입니다.' },
  //   { mytext: '반가워요.' },
  // ];

  // Stats map
  // const statsData = [
  //   { title: '사업의 지속가능성', subtitle: '14개월간', subtitle2: '작은제목2에요', description: '45건의 콘첸츠 제작 및 누적 약 9만명 참여', description2: '내용2' },
  //   { title: '큰 제목입니다1', subtitle: 'AR/VR기반 3D 가상공간 투어', subtitle2: '작은제목2에요', description: '총 매출 1.5억원 및 누적 사용자 약 9만명', description2: '내용2' }
  // ];
  const sections = [
    {
      title: '공간을 플레이하다',
      subtitle: "AR / VR",
      description: '360 어라운드 뷰를 통해 사용자는 진정한 몰입 경험을 누릴 수 있습니다. 우리는 XR(확장현실) 기술을 활용하여 감각적이고 상호 작용적인 360도 시각 환경을 제작합니다.',
      image: "/Sec_Content01.jpg",
      href: '/Xr/Vr',
      isReversed: true
    },
    {
      title: '공간을 플레이하다',
      subtitle: "AR / VR",
      description: 'VR/AR 기술로 구현한 메타버스 공간 속에서 공간의 이야기로 플레이하며,공간의 이야기로 게임 스토리를 만들어 3D 가상투어 시 참여자가 스토리를 따라 몰입할 수 있습니다.',
      image: "/Sec_Content02.jpg",
      href: '/Xr/Ar',
      isReversed: false
    }
  ]
  return (
    <Box w={'auto'} height={'auto'} position="relative" overflow="hidden">
      <Box w={'atuo'} height={"90vh"} position={"relative"} overflow={"hidden"} justifyContent={'center'} alignItems={'center'} display='flex' flexDirection={"column"}>
        <Box width={{ base: 'full', sm: 'lg', lg: '2xl' }} display="flex" flexDirection="column">


          <Image
            src='/Herobackground/Hero_title_Content.webp'
            alt="hero image"
            style={{
              width: "400px",
              height: "200px",
              objectFit: "fill",
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: '-1'
            }}
          />
          <Box position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
            zIndex={'-2'}>
          </Box>
          {/* <chakra.h3
            fontFamily={'Work Sans'}
            fontWeight={'bold'}
            fontSize={{base:'sm', md:'md'}}
            pt={24}
            textTransform={'uppercase'}
            textAlign={'center'}
            color={'#01E96B'}>
            하나의 현실, 무한한 경험
          </chakra.h3>
          <chakra.h1
            py={5}
            fontSize={{base:'xl',sm:'2xl', md:'3xl',lg:'4xl'}}
            fontFamily={'Work Sans'}
            fontWeight={'bold'}
            textAlign={'center'}
            color="gray.300">
            현실 너머의 세계를 경험하다
          </chakra.h1>
          <chakra.h2
            margin={'auto'}
            width={'70%'}
            textAlign={'center'}
            fontFamily={'Inter'}
            fontWeight={'medium'}
            fontSize={{base:'sm',md:'md'}}
            color='gray.400'>
            우리 {' '}오르비터
            는 VR / AR 기술을 결합한 3D기술로 미래를 여러분과 함께 열어나갑니다
          </chakra.h2>
          스크롤 가이더 */}


          <ButtonContainer />
          <ScrollDownIndicator />
        </Box>

        {/*영상 관련부분  */}
        <Box position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          // backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
          zIndex={'-3'}>
        </Box>
        <Box
          as='video'
          // src='orbitor_background.mp4'
          src='https://orbitor-storage.s3.ap-northeast-2.amazonaws.com/orbitor_background.mp4'
          autoPlay
          muted
          loop
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: '-3'
          }}
        >
        </Box>
        {/* <Box>
          <Image
            objectFit={'cover'}
            src='/testbackground.png'
            boxSize={"auto"}
            alt='테스트백그라운드'
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Box> */}
      </Box>
      <SlidingText />
      <Flex>
        <BannerSection
          backgroundImage="/Content_003.jpg"
          title='창의력의 한계를 넘어'
          subtitle='당신의 상상을 현실로 만드는 3D 및 영상 제작 파트너'
          src='/Company'
        />
      </Flex>

      {/* 2번섹션 */}
      <Flex width={'100%'} height={'auto'} align={'center'} justify={'center'} direction="row" my={12}>
        <VStack spacing={0} divider={<Box borderColor="gray.200" borderStyle="solid" borderWidth={1} />} width="100%">
          {sections.map((section, index) => (
            <Box key={index} width="100%" height={{ base: 'auto', md: 'fit-content' }} position="relative" my={12}>
              <StickySection {...section} />
            </Box>
          ))}
        </VStack>
      </Flex>

      <Flex position="relative">
        <BannerSection
          backgroundImage="/banner/banner001.jpg"
          title='당신을 위한 세상을 만들어나가는'
          subtitle='메타선두주자 오르비터가 함께 만들어나갑니다.'
          src='/Xr'
        />
      </Flex>

      {/* 3번섹션 */}
      <Flex position="relative" height={'auto'}>
        <SosokSticky />
      </Flex>

      {/* 베너섹션 */}
      <Flex position="relative">
        <BannerSection
          backgroundImage="/AR_Mock_up.jpg"
          title='함께 만드는 세상'
          subtitle='더 많은 정보들을 같이 가치있게'
          src='/Services'
        />
      </Flex>


      {/* 설명(4번째섹션) */}
      <Flex justifyContent='center' position="relative" height={"auto"} bg={'gray.800'} pt={'30'}>
        <MyCardList />
      </Flex>
    </Box >
  );
};
