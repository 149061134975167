import React from "react";
import { Box } from '@chakra-ui/react'
import Header from './Header';
import Footer from './Footer';


type LayoutsProps = {
    children: React.ReactNode;
}

export const Layouts = ({ children }: LayoutsProps) => {
    return (
        <Box>
            <head>
                <title>오르비터(orbitor)</title>
                <link rel="icon" href="/symbol_orbitor.webp" />
            </head>

            <Box flexDir="column" minH={"100vh"}>
                <Header />
                <Box maxW="auto">
                    {children}
                </Box>
                <Footer />
            </Box>
        </Box>
    )
}