'use client'
import React from "react";

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    // useColorModeValue,
    Text,
    Container,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

export function Arccordion() {

    const items = [
        {
            question: "오르비터에 컨텐츠제작 문의는 어떻게 하나요?",
            answer:
            `우리 "오르비터" 는 AR / VR 관련된 영상편집,기획,제작등 여러 컨텐츠를 제작하고있습니다. 문의는 Service메뉴 -> Service 홈화면 -> "문의하기" 를 눌러주시면 됩니다. `
        },
        {
            question: "메타버스 관련 컨텐츠 제작도 가능한가요?",
            answer:
            `네, "오르비터"는 메타버스 관련 다양한 컨텐츠 제작에 특화되어 있습니다. 개별 프로젝트부터 대규모 플랫폼까지 여러 메타버스 환경에 맞는 컨텐츠를 제작할 수 있습니다.`
        },
        {
            question: "오르비터의 XR(AR, VR) 컨텐츠 제작 비용은 어떻게 되나요?",
            answer:
            `컨텐츠 제작 비용은 프로젝트의 규모, 복잡도, 필요한 리소스 등 다양한 요인에 따라 달라집니다. 구체적인 견적은 Service메뉴 -> Service 홈화면 -> "문의하기"를 통해 상세히 안내드립니다.`
        },
        {
            question: "제작된 컨텐츠는 어떤 플랫폼에서 사용할 수 있나요?",
            answer: `우리는 다양한 플랫폼에 최적화된 컨텐츠를 제작합니다. 따라서 "오르비터"에서 제작한 컨텐츠는 PC, 모바일, VR 헤드셋 등 다양한 디바이스와 플랫폼에서 사용할 수 있습니다.`
        },
        {
            question: "XR(AR, VR) 컨텐츠의 제작 과정은 어떻게 되나요?",
            answer: `"오르비터"는 초기 기획부터 제작, 배포까지 XR(AR, VR) 컨텐츠 제작의 전 과정을 지원합니다. 고객의 아이디어와 요구사항을 기반으로 최고의 경험을 제공하는 컨텐츠를 만들어냅니다.`
        },
        {
            question: "NFT 컨텐츠 제작 서비스도 제공하나요?",
            answer: `네, "오르비터"에서는 NFT로 발행될 아트워크나 다른 디지털 자산의 컨텐츠 제작 서비스를 제공하고 있습니다. 고객의 요구사항에 맞춰 특화된 NFT 컨텐츠를 제작해드립니다.`
        }
    ]

    return (
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}>
        <Container 
            maxW={'1440px'}
            w={'100%'}
        >
          <Accordion allowMultiple width="100%" maxW="1440px" rounded="lg">
            {items.map((item, index) => (
              <AccordionItem key={index}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  py={10}>
                  <Text fontSize="lg" fontWeight={'bold'}>{item.question}</Text>
                  <ChevronDownIcon fontSize="24px" />
                </AccordionButton>
                <AccordionPanel pb={16}>
                  <Text color="gray.300" fontSize={'sm'}>{item.answer}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Flex>
    )
}