'use client'

import React from 'react'
import {
  Box,
  IconButton,
  useBreakpointValue,
  // Stack,
  // chakra,
  // Heading,
  // Text, 
  // Container,
  Flex,
  // VStack,
  Image,
} from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick'
import ScrollDownIndicator from '../../components/ScrolledDownIndicator'
import { History } from '../../components/History'
// import { title } from 'process'
import { YoutubOrbitor } from '../../components/YoutubeOrbitor'

// Settings for the slider
const settings = {
  dots: true,
  arrows: true,
  fade: false,
  infinite: true,
  autoplay: true,
  speed: 1250,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default function Company() {
  const [slider, setSlider] = React.useState<Slider | null>(null)
  const top = useBreakpointValue({ base: '50%', md: '50%' })

  const cards = [
    {
      slogan: '혁신 ',
      title: '혁신을 주도하는 기업',
      src: '/Herobackground/오르비터_히어로섹션_TITLE(company_혁신).webp',
      text: "프리랜서, 대행사, 야간작업자에 이상적인 계약 작업 전용 게시판입니다. 혁신적인 디자인과 개발을 통해 시장을 선도하고, 고객에게 새로운 가치를 제공합니다.",
      image:
        '/Carousel01.jpg',
    },
    {
      slogan: '미래',
      title: '미래를 열어가는 기업',
      src: '/Herobackground/오르비터_히어로섹션_TITLE(company_미래).webp',
      text: "오르비터는 미래 라는 타이틀에 걸맞게 혁신적인 아이디어와 전략적 파트너십으로 끊임없이 시장을 선도해 나가며, 모든 프로젝트에 이상을 현실로 만드는 오르비터의 비전을 반영합니다.",
      image:
        '/Carousel02.jpg',
    },
    {
      slogan: '도전',
      title: '멈추지않는 열정',
      src: '/Herobackground/오르비터_히어로섹션_TITLE(company_도전).webp',
      text: "많은 투자자, 제휴업체, 회사의 젊은 인재들로 구성된 오르비터는, 도전을 두려워하지않고 앞으로 더 나아가기 위해 언제나 귀 기울이며 발전해 나갑니다.",
      image:
        '/Carousel03.jpg',
    },
  ]

  const showArrows =useBreakpointValue({base:false, md:true});

  return (
    <Box position='relative' height={'auto'} width={'full'} overflow={'hidden'}>
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            height={'90vh'}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={card.src}
              alt="hero image"
              style={{
                width: "400px",
                height: "200px",
                objectFit: "fill",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: '4'
              }}
            />
            {/* <Box position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
              zIndex={'-2'}>
            </Box> */}
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              background={`url(${card.image})`}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundColor="rgba(0, 0, 0, 0.6)"
              backgroundSize="cover"
              backgroundBlendMode="multiply">

              {/* <VStack
                maxWidth={"1440px"}
                spacing={4}
                p={{ base: 4, lg: 8 }}
                width={{ base: '100vw', sm: '480px', md: '600px', lg: '720px' }}
                textAlign="center"
                color="white"
                zIndex="1"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <chakra.h3
                  fontFamily={'Work Sans'}
                  fontWeight={'bold'}
                  fontSize={20}
                  textTransform={'uppercase'}
                  color={'#01E96B'}>
                  {card.slogan}
                </chakra.h3>
                <chakra.h1
                  fontSize={{ base: 'x-large', md: '2xl', lg: '4xl' }} // 반응형 폰트 사이즈
                  fontFamily={'Work Sans'}
                  fontWeight={'bold'}
                  color="whitesmoke">
                  {card.title}
                </chakra.h1>
                <chakra.h2
                  fontFamily={'Inter'}
                  pt={12}
                  fontWeight={'medium'}
                  color='whiteAlpha.700'>
                  {card.text}
                </chakra.h2>
              </VStack> */}
              {/* 스크롤 가이더 */}
              <ScrollDownIndicator />
            </Box>

            {/* 캐로셀 I Con */}
            <Flex
              display={showArrows ? "flex" : "none"}
              position="absolute"
              left={0}
              right={0}
              top={top}
              px={16}
              justifyContent="space-between"
              width={'full'}
              alignItems="center"
              zIndex={2}
              transform={'translateY(-50%)'} // 상단에서 50%만큼 위로 이동합니다.
            >
              {/* Left Icon */}
              <IconButton
                aria-label="left-arrow"
                variant="ghost"
                color="gray.50"
                _hover={{
                  bg: 'none',
                  color: '#01E96B',
                  transform: 'scale(1.3)',
                }}
                onClick={() => slider?.slickPrev()}>
                <BiLeftArrowAlt size="50px" />
              </IconButton>
              {/* Right Icon */}
              <IconButton
                aria-label="right-arrow"
                variant="ghost"
                color="gray.50"
                _hover={{
                  bg: 'none',
                  color: '#01E96B',
                  transform: 'scale(1.3)',
                }}
                onClick={() => slider?.slickNext()}
              >
                <BiRightArrowAlt size="50px" />
              </IconButton>
            </Flex>
          </Box>
        ))}
      </Slider>

      <Flex justifyContent={'center'} direction={'column'} gap={{base:'none', md:4}}>
        <YoutubOrbitor />
      </Flex>

      {/* 2번섹션 */}
      <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <History />
        {/* <BannerSection backgroundImage='/blog_image_orbitor.png' /> */}
      </Flex>
    </Box>
  )
}