'use client'
import React from "react";

import {
  Box,
  // chakra,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  // VisuallyHidden,
  List,
  ListItem,
  AspectRatio,
} from '@chakra-ui/react'
import { MdLocalShipping } from 'react-icons/md'

type User ={
  name: string;
  subname: string;
  src:string;
  embedurl?:string;
  description:string;
}

export default function Product({ name, subname, src, embedurl, description }:User) {

  const RederVideo = () => {
    if (embedurl) {
      return (

        <AspectRatio ratio={16 / 9}

          objectFit={'cover'}
          rounded={'md'}
          maxWidth={'100%'}
          w={"1050px"}
          margin={'auto'}
        >
          <iframe
            height={"100%"}
            width={"100%"}
            src={embedurl}
            allowFullScreen
          />
        </AspectRatio>
      );
    } else {
      return (

        <AspectRatio
          as={"video"}
          margin={'auto'}
          autoPlay
          controls
          muted
          loop
          objectFit={'fill'}
          rounded={'md'}
          w={'100%'}
          maxWidth={'1050px'}
          h={{ base: '100%', sm: 'auto', lg: 'auto' }}>
          <source src={src} />
        </AspectRatio>
      );
    }
  }

  return (
    <Flex justifyContent={'center'} alignItems={'center'} display={'flex'}>
      <Container maxW={'1440px'} >
        <SimpleGrid

          columns={{ base: 1, lg: 1 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 24 }}>

          {RederVideo()}

          <Stack spacing={{ base: 6, md: 10 }}>
            <Box as={'header'}>
              <Heading
                textAlign='center'
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '2xl', md: '4xl' }}>
                {name}
              </Heading>
            </Box>

            <Stack
              spacing={{ base: 4, sm: 6 }}
              maxW={'1050px'}
              w={'100%'}
              margin={'auto'}
              direction={'column'}
              divider={
                <StackDivider borderColor='gray.500' />
              }>
              <VStack spacing={{ base: 4, sm: 6 }}>
                <Image src={description} alt="Section image" w="full" h="full" objectFit="cover" />
              </VStack>

              <Box justifyContent={'center'}>
                <Text
                  textAlign='center'
                  fontSize={{ base: '16px', lg: '18px' }}
                  color={useColorModeValue('yellow.500', 'yellow.300')}
                  fontWeight={'500'}
                  textTransform={'uppercase'}
                  mb={'4'}>
                  {subname}
                </Text>

                <List display="flex" flexDirection="column" alignItems="center" spacing={2} justifyContent={'center'}>
                  <ListItem display='flex' flexDirection='column' alignItems='center' >
                    {/* -<br />
                    <Text as={'div'} fontWeight={'bold'} width={{ base: '90%', sm: '100%' }} fontSize={"md"}>
                      3D가상공간 투어: 메타버스로의 초대

                      우리의 상상을 초월하는 경험, 메타버스가 여러분을 기다리고 있습니다.<br /> 메타버스는 단순히 가상 현실을 넘어서,
                      우리가 상호 작용하고, 탐험하며, 새로운 가능성을 발견할 수 있는 가상공간 입니다.<br />
                      이곳은 일상의 경계를 넘어서며,꿈과 현실 사이의 간극을 메우는 차원입니다.<br />
                      3D가상공간 투어를 통해, 메타버스의 심장부로 여러분을 안내합니다. 여기서는 한계가 없습니다. <br />

                      우리가 만들어낸 이 세계는, 새로운 형태의 커뮤니케이션, 엔터테인먼트, 교육, <br />
                      그리고 비즈니스를 가능하게 합니다.<br />
                      마치 실제와 같은 3D 공간에서, 여러분은 자유롭게 걸을 수 있고,<br />
                      사람들과 만나 교류할 수 있으며,새로운 환경을 만들거나 경험할 수 있습니다.<br />
                    </Text>
                    -<br />
                    <Text as={'div'} fontWeight={'bold'} width={{ base: '90%', sm: '100%' }}>
                      메타버스에서의 가상공간 투어 는 단순한 여행이 아닙니다. 이것은 현실을 넘어서는 모험입니다.<br />
                      각각의 가상공간은 독특한 테마와 이야기를 담고 있으며, 각자 취향과 관심사에 맞는 경험을 제공합니다. <br />
                      건축의 거장들이 설계한 도시의 스카이라인부터, 마법의 숲, <br />
                      심지어는 외계 행성에 이르기까지,무한한 세계가 여러분의 발길을 기다리고 있습니다.<br />
                      이 투어를 통해 메타버스의 개념을 이해하고, 가상공간의 무한한 가능성을 직접 체험해 보세요.<br />
                      메타버스는 단지 먼 미래의 이야기가 아닙니다. 그것은 이미 현실이 되어가고 있으며,<br /> 여러분이 이를 만들어가는 중심에 서 있습니다.<br />
                      3D가상공간 투어는 이 새로운 세계로의 첫걸음이 될 것입니다.그렇다면 이제 준비되셨나요? <br />
                      메타버스로의 여정을 시작할 시간입니다.가상과 현실의 경계를 넘나들며,<br /> 
                      새로운 차원의 자유와 창조성을 경험하는 여행에 여러분을 초대합니다.<br />
                    </Text> */}
                    <Image src="/AR_가상공간투어설명.webp" alt="Section image" w="full" h="full" objectFit="cover" />
                  </ListItem>

                </List>
              </Box>
            </Stack>

            <Button
              margin='auto'
              rounded={'none'}
              w={'320px'}
              mt={5}
              size={'lg'}
              py={'3'}
              fontWeight={'bold'}
              fontSize={'xl'}
              bg='#36E579'
              color='gray.900'
              _hover={{
                transform: 'translateY(2px)',
                boxShadow: 'lg',
              }}>
              문의하기
            </Button>

            <Stack direction="row" alignItems="center" justifyContent={'center'}>
              <MdLocalShipping />
              <Text fontSize={{ base: 'sm', md: 'md' }}>문의를 주시면 곧바로 상담 연락드리겠습니다</Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Flex>
  )
}