import { Box, Text, Textarea } from "@chakra-ui/react";
import React from "react";

const PrivacyPolicy = () => {

    const policyText = `
    오르비터(이하 ‘회사’)는 서비스 기획부터 종료까지 정보통신망 이용촉진 및 정보보호 등에 
    관한 법률(이하 ‘정보통신망법’),
    개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다.
 
    수집하는 개인정보
    회사는 이용자가 회원제 기반의 다양하고 편리한 서비스 이용을 원하는 경우, 
    서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
    회원가입 시 아이디(이메일 주소), 비밀번호, 이름(닉네임), 휴대전화번호를 
    필수항목으로 수집하며, 
    사진(메타정보 포함), 소속단체가 선택적으로 수집합니다. 단, sosok의 자체 계정이 아닌 
    카카오계정 등의 타서비스 
    계정을 이용하여 회원 가입을 할 경우 해당 계정의 유저아이디,
    이름(닉네임)을 필수항목으로 수집하며, 공개 프로필 정보 (사진, 이메일 주소 등)를 
    선택적으로 수집합니다. 서비스 이용 시 단말기정보
    (모델명, 이동통신사 정보, OS정보, 언어 및 국가정보, 화면사이즈, 디바이스 아이디 등), 
    IP주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등의 정보가 자동으로
    생성되어 수집될 수 있습니다
    sosok은 회원가입 과정에서 최소한의 정보만을 수집하기 때문에 개별 서비스 이용, 
    이벤트 응모 및 경품 신청 등의 
    과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.

    멤버십서비스 이용 시
    가맹점에서의 혜택 적용을 위한 멤버십 서비스 이용 과정에서 
    아래와 같은 정보를 수집할 수 있습니다.
    수집하는 개인정보 이름, 소속단체 : 가맹점에서 멤버십 혜택 적용을 위한 권한 확인
    당첨자 이름, 소속단체, 휴대전화번호 : 내부적으로 운영하는 이벤트 당첨 시

    개인정보 수집 방법
    sosok은 회원가입 시 서비스에서 이용자로부터 직접 입력 받는 방식으로 
    필요한 최소한의 개인정보를 수집하며, 
    가맹점 혜택 적용을 위한 소속단체 구분 과정에서 추가적인 정보를 수집하고 있습니다. 
    오프라인으로 진행되는 이벤트 및 세미나 등에서 서면을 통해 개인정보를 수집할 수 있으며, 
    고객센터 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해서도 수집할 수 있습니다. 
    sosok은 개인정보의 수집이 발생하는 경우, 반드시 ‘개인정보 수집 및 이용’에 대한 
    이용자의 사전 동의를 받습니다. 
    다만 IP 주소, 쿠키, 기기정보 등의 경우 PC 웹, 모바일 웹/앱으로 서비스를 
    이용하는 과정에서 자동으로 생성되어 수집될 수 있습니다.
    수집한 개인정보의 이용
    sosok은 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경 
    구축 등 아래의 목적으로만 개인정보를 이용합니다.
    회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 
    이용자 및 법정대리인의 본인 확인, 이용자 식별, 
    회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
    콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 
    서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 
    기반한 이용자간 관계의 형성, 지인 및 관심사 
    등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 
    서비스 개선 등을 위하여 개인정보를 이용합니다.
    법령 및 sosok 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 
    서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 
    계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 
    민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
    서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 
    본인 인증 및 멤버십 이용 등을 위하여 개인정보를 이용합니다.
    이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 
    마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
    서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 
    서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
    보안, 프라이버시, 안전 측면에서 이용자가 안심하고 
    이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
     
     
    개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
    쿠키란?
    회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 
    정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
    쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는
    아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 

    이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의
    하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 
    환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
    쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 
    이용자는 언제든지 이러한 쿠키의 저장을 거부하거나  삭제할 수 있습니다.
     
    회사의 쿠키 사용 목적
    회사는 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 
    이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 
    서비스 개선에 활용합니다.회사는 쿠키를 통해 서비스의 접속 빈도, 
    방문 시간, 방문 회수 등을 분석하여 이용자의 취향과 관심분야를 파악하여 
    서비스 제공에 활용합니다.

    쿠키의 설치/운영 및 거부
    다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스의 경우,
    이용에 어려움이 있을 수 있습니다.
    쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다. 
    [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.[개인정보 탭]을 클릭합니다.
    [개인정보처리 수준]을 설정하시면 됩니다.
    [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
    [개인정보 탭]을 클릭합니다.
    [개인정보처리 수준]을 설정하시면 됩니다.
     
    개인정보의 제공 및 위탁
    sosok은 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다.
    sosok은 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 
    단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우,
    그리고 관련 법령에 의거해 sosok에 개인정보 제출 의무가 발생한 경우, 
    이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 
    경우에 한하여 개인정보를 제공하고 있습니다.
    sosok은 편리하고 더 나은 서비스 제공을 위하여
    필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 
    위탁받은 업체가 정보통신망법에 따라 개인정보를 안전하게 처리하도록 
    필요한 사항을 규정하고 관리/감독을 하고 있습니다.
    개인정보의 제공 및 위탁
    Amazon Web Services Inc. : 데이터 보관
     
     
    개인정보의 파기
     
    회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
    단 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 
    또는 법령에서 일정 기간 정보보관 의무를 부과하는 
    경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
    이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우는 아래와 같습니다.
    부정가입 및 징계기록 등의 부정이용기록은 부정 가입 및 이용 방지를 위하여
    수집 시점으로부터 6개월간 보관하고 파기하고 있습니다.
    부정이용기록 내 개인정보는 가입인증 이메일, 휴대폰번호가 있습니다.
    전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 
    통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다.
    sosok은 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 
    본 정보를 다른 목적으로는 절대 이용하지 않습니다.

    전자상거래 등에서 소비자 보호에 관한 법률 계약 또는 청약철회 등에 관한 기록: 5년 
    보관대금결제 및 재화 등의 공급에 관한 기록: 5년 
    보관소비자의 불만 또는 분쟁처리에 관한 기록: 3년 
    보관전자금융거래법 전자금융에 관한 기록: 5년 
    보관통신비밀보호법 로그인 기록: 3개월 보관
    전자상거래 등에서 소비자 보호에 관한 법률 계약 또는 청약철회 등에 관한 기록: 5년 
    보관대금결제 및 재화 등의 공급에 관한 기록: 5년 
    보관소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
    계약 또는 청약철회 등에 관한 기록: 5년 보관
    대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
    소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
    전자금융거래법 전자금융에 관한 기록: 5년 보관
    전자금융에 관한 기록: 5년 보관
    통신비밀보호법 로그인 기록: 3개월 보관
    로그인 기록: 3개월 보관
     
    회원탈퇴, 서비스 종료, 이용자에게 동의받은 개인정보 보유기간의 도래와 같이 
    개인정보의 수집 및 이용목적이 달성된 개인정보는 
    재생이 불가능한 방법으로 파기하고 있습니다.
    법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후
    지체없이 재생이 불가능한 방법으로 파기합니다.
    전자적 파일 형태의 경우 복구 및 재생이 되지 않도록
    기술적인 방법을 이용하여 안전하게 삭제하며, 
    출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.
    참고로 sosok은 ‘개인정보 유효기간제’에 따라 1년간 서비스를 
    이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 
    관리하고 있습니다.
     
    이용자의 권리와 행사 방법
     
    이용자는 언제든지 <‘ sosok ’> 마이페이지’에서 
    자신의 개인정보를 조회하거나 수정할 수 있습니다.이용자는 언제든지 
    ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
    이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 
    정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
    또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 
    처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
     
    개인정보 보호책임자 및 담당자 안내
     
    sosok 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 
    개인정보 보호책임자 및 담당자를 지정하고 있습니다.
    개인정보 보호책임자
     
    이름: 이준석
    소속: 청개구리
    직위: 대표
    전화: 010-2462-5453
    메일: jslee@bluefrog.co.kr
     
    개인정보 보호담당자
     
    이름: 양수장
    소속: 청개구리
    직위: 개발팀장
    전화: 010-2218-7198
    메일: sjyang@bluefrog.co.kr
     
    기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.
     
    개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
    대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
    경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
     
    본 개인정보처리방침의 적용 범위
     
    본 개인정보처리방침은 회사의 브랜드 중 하나인 
    ‘ sosok ’ 및 관련 제반 서비스(모바일 웹/앱 포함)에 적용되며, 
    다른 브랜드로 제공되는 서비스에 대해서는 별개의 개인정보처리방침이 적용될 수 있습니다.
    sosok에 링크되어 있는 다른 회사의 웹사이트에서 개인정보를 수집하는 경우, 
    이용자 동의 하에 개인정보가 제공된 이후에는 본 개인정보처리방침이 적용되지 않습니다.
     
    개정 전 고지 의무
     
    본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 
    ‘공지사항’을 통해 사전 공지를 할 것입니다.다만, 
    수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 
    중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 
    필요 시 이용자 동의를 다시 받을 수도 있습니다.
     
    공고일자: 2019년 4월 4일
    시행일자: 2019년 4월 4일
     
    이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
    `


    return (
        <Box sx={{ mt: 4, mb: 2, p: 4, overflow: 'auto',minH:'70vh' , border: '1px solid #ccc', borderRadius: '4px', bgcolor: '#f9f9f9' }} >
            {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}> */}
            <Text fontSize="lg" fontWeight={"bold"} sx={{ pb: 4 }}>
                개인정보 처리방침
            </Text>
            {/* </Typography> */}

            <Textarea
                style={{
                    width: '100%',
                    border: '1px solid 0.5',
                    outline: 'none',
                    marginTop: '0px',
                    marginBottom: '6px',
                    resize: 'block',
                    height: '50vh',
                    fontSize: '0.7em'
                }}
                sx={{
                    '&::-webkit-scrollbar': {
                        width: '12px', // 스크롤바 너비 조정
                        borderRadius: '30px', // 스크롤바 모서리 둥글게
                        backgroundColor: `rgba(0, 0, 0, 0.05)`, // 스크롤바 트랙 색상
                    },

                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: `rgba(0, 0, 0, 0.5)`, // 스크롤바 썸 색상
                        borderRadius: '30px', // 스크롤바 썸 모서리 둥글게
                    },
                    scrollbarWidth: 'none', // Firefox
                }}
                value={policyText}
                readOnly
            />

            <Box textAlign="center" sx={{ mt: 2 }} >
                {/* <Typography sx={{ fontSize: '0.7em' }}> */}
                <Text fontSize="x-small">
                    본인은 위의 내용을 충분히 숙지하였으며,
                    개인정보이용 및 제공하는 것에 동의합니다.
                    <br />
                    개인의 정보는 접수 외 다른용도로 사용하지않음을 알려드립니다.
                </Text>
            </Box>
        </Box>
    )
}

export default PrivacyPolicy;