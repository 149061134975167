// TypeScript Component for Sliding Text with Icon
import React from "react";
import { Box, Flex, Icon, chakra, useColorModeValue } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionFlex = motion(Flex);

export const SlidingText = () => {
  const slidingAnimation = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: { type: "spring", stiffness: 100, duration: 2 },
        opacity: { duration: 0.5 }
      }
    },
    exit: { x: 100, opacity: 0 }
  };

  const bgColor = useColorModeValue('gray.800', 'yellow.600'); // Or any color from your theme

  return (
    <>
      <Box bg={bgColor} w="full" overflow="hidden">
        <MotionFlex
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={slidingAnimation}
          alignItems="center"
          justifyContent="flex-start"
          py={2}
        >
          <chakra.span mr={2}> Infinite Universe Orbitor</chakra.span>
          <Icon as={FaArrowRight} />
        </MotionFlex>
      </Box>
    </>
  );
};

export default SlidingText;
