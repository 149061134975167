import { Arccordion } from '../../components/Arccordion';
import WorksSection from '../../components/CardSection';
import { ButtonContainer } from '../../components/MainButton';
import ScrollDownIndicator from '../../components/ScrolledDownIndicator';
// import { CheckIcon } from '@chakra-ui/icons';
import { Box, Image } from '@chakra-ui/react';
// import Head from 'next/head';
import React from 'react';

export default function Services() {
    // const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box>
            <Box w={'atuo'} height={"90vh"} position={"relative"} overflow={"hidden"} justifyContent={'center'} alignItems={'center'} display='flex' flexDirection={"column"}>
                <Box width={{ base: 'full', md: '2xl', lg: '3xl' }} display="flex" flexDirection="column" >


                    <Image
                        src='/Herobackground/Hero_title_Services.webp'
                        alt="hero image"
                        style={{
                            width: "400px",
                            height: "200px",
                            objectFit: "fill",
                            position: "relative",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: '-1'
                        }}
                    />
                    <Box position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
                        zIndex={'-2'}>
                    </Box>
                    {/* <chakra.h3
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        fontSize={{base:'xx-small', md:'sm'}}
                        pt={"80"}
                        textTransform={'uppercase'}
                        textAlign={'center'}
                        color={'#36E579'}>
                        “One reality, infinite experience.”
                    </chakra.h3>
                    <chakra.h1
                        py={5}
                        fontSize={{base:'2xl', md:'3xl',lg:'4xl'}}
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        color="gray.300">
                        오르비터만의 특별함
                    </chakra.h1>
                    <chakra.h2
                        margin={'auto'}
                        width={'70%'}
                        fontFamily={'Inter'}
                        textAlign={'center'}
                        fontWeight={'medium'}
                        fontSize={{base:'xs', md:'medium'}}
                        color='gray.200'>
                        관광, 엔터테인먼트, 비즈니스 등 모든 분야에서 새로운 경험을 제공하며, 당신이 누구든, 어디에 있든, 원하는 대로 가상공간을 체험할 수 있습니다.
                    </chakra.h2> */}


                    {/* 구독 모달창 */}
                    {/* <Box margin="auto" display={'flex'}>
                        <SubscribeModal isOpen={isOpen} onClose={onClose} />
                    </Box> */}
                    <ButtonContainer />
                    {/* 스크롤 가이더 */}
                    <ScrollDownIndicator />
                </Box>

                {/*영상 관련부분  */}
                <Box position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
                    zIndex={'-2'}></Box>
                <Image
                    src='/bg_Services_Hero.jpg'
                    alt="hero image"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: '-3'
                    }}
                />
            </Box>


            {/* 2번 섹션 */}

            <Box height={"auto"} mt={24}>
                <WorksSection />
            </Box>

            {/* 3번 섹션 */}
            <Arccordion />
        </Box >
    );
}


// 모달창 코드
// export function SubscribeModal({ isOpen, onClose }: any) {
//     const [email, setEmail] = useState('')
//     const [state, setState] = useState<'initial' | 'submitting' | 'success'>('initial')
//     const [error, setError] = useState(false)

//     return (
//         <Modal isOpen={isOpen} onClose={onClose} isCentered>
//             <ModalOverlay />
//             <ModalContent>
//                 <ModalCloseButton />
//                 <Flex
//                     minH={'100vh'}
//                     align={'center'}
//                     justify={'center'}
//                     bg={useColorModeValue('gray.50', 'gray.800')}>
//                     <Container
//                         maxW={'lg'}
//                         bg={useColorModeValue('white', 'whiteAlpha.100')}
//                         boxShadow={'xl'}
//                         rounded={'lg'}
//                         p={6}>
//                         <Heading
//                             as={'h2'}
//                             fontSize={{ base: 'xl', sm: '2xl' }}
//                             textAlign={'center'}
//                             mb={5}>
//                             컨텐츠 제작 신청 및 문의
//                         </Heading>
//                         <Stack
//                             direction={{ base: 'column', md: 'row' }}
//                             as={'form'}
//                             spacing={'12px'}
//                             onSubmit={(e: FormEvent) => {
//                                 e.preventDefault()
//                                 setError(false)
//                                 setState('submitting')

//                                 // remove this code and implement your submit logic right here
//                                 setTimeout(() => {
//                                     if (email === 'fail@example.com') {
//                                         setError(true)
//                                         setState('initial')
//                                         return
//                                     }

//                                     setState('success')
//                                 }, 1000)
//                             }}>
//                             <FormControl>
//                                 <Input
//                                     variant={'solid'}
//                                     borderWidth={1}
//                                     color={'gray.800'}
//                                     _placeholder={{
//                                         color: 'gray.400',
//                                     }}
//                                     borderColor={useColorModeValue('gray.300', 'gray.700')}
//                                     id={'email'}
//                                     type={'email'}
//                                     required
//                                     placeholder={'Your Email'}
//                                     aria-label={'Your Email'}
//                                     value={email}
//                                     disabled={state !== 'initial'}
//                                     onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
//                                 />
//                             </FormControl>
//                             <FormControl w={{ base: '100%', md: '40%' }}>
//                                 <Button
//                                     colorScheme={state === 'success' ? 'green' : 'blue'}
//                                     isLoading={state === 'submitting'}
//                                     w="100%"
//                                     type={state === 'success' ? 'button' : 'submit'}>
//                                     {state === 'success' ? <CheckIcon /> : 'Submit'}
//                                 </Button>
//                             </FormControl>
//                         </Stack>
//                         <Text mt={2} textAlign={'center'} color={error ? 'red.500' : 'gray.500'}>
//                             {error
//                                 ? 'Oh no an error occured! 😢 Please try again later.'
//                                 : "이메일을 통해 담당자를 배정해 드리겠습니다."}
//                         </Text>
//                     </Container>
//                 </Flex>
//             </ModalContent>
//         </Modal>
//     )
// }
