import { useState } from "react";

function useWorkationForm() {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        phone: '',
        companyInfo: {
            companyName: '',
            companyAddress: '',
            category:'',
            team: '',
            position: ''
        }
    });

    const [ applicationData, setApplicationData  ]= useState({
        package: '',
        checkIn: '',
        checkOut: '',
        price:'',
    });

    const updateUserData = (field:any, value:any) => {
        setUserData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const updateCompanyInfo = (field:any, value:any) => {
        setUserData(prev => ({
            ...prev,
            companyInfo: {
                ...prev.companyInfo,
                [field]: value
            }
        }));
    };

    const updateApplicationData = (newData: {[key: string]:any}) => {
        setApplicationData(prev => ({
            ...prev,
            ...newData
        }));
    };

    return {
        userData,
        applicationData,
        updateUserData,
        updateCompanyInfo,
        updateApplicationData
    };
}

export default useWorkationForm;