'use client'

import React from "react";
import { Box, List, ListItem, Heading, VStack, Image, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import { exit } from 'process';

export const History = () => {

    const items = [
        {
            title: '2022 인천 송도의밤',
            href: 'https://www.songdonight.com/',
            description: "/History_송도의밤.webp",
            image: '/songdonight.webp'
        },
        {
            title: '2022년 청년창업챌린지 협약식 개최',
            href: 'https://www.etnews.com/20220717000105',
            description: "/History_청년창업챌린지.webp",
            image: '/청년창업챌린지.jpg'
        },
        {
            title: '2021년 공모 지역혁신부문 수상실적',
            description: "/History_관광기업지원센터03.png",
            // href: '#',
            image: '/관광기업지원센터03.png'
        },
        {
            title: '2021년 입주기업선정',
            description: '/History_관광기업지원센터01.png',
            // href: '#',
            image: '/관광기업지원센터01.png'
        },
        {
            title: '2021년 인천 미식 관광상품 공모전 최종 선정 (인천관광공사)',
            description: "/History_인천미식관광상품공모.webp",
            // href: '#',
            image: '/인천미식관광상품공모.webp'
        },
        {
            title: '2020년 창업패키지',
            description: "/History_관광기업지원센터02.png",
            // href: '',
            image: '/관광기업지원센터02.png'
        },
    ]
    // 반응형 함수
    const widthValue = useBreakpointValue({ base: "100%", lg: "100%" });
    const itemMaxWidth = useBreakpointValue({ base: "100%", lg: "75%" });

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 2
            }
        },
        done: { opacity: 0 }
    };
    const lineLength = `${items.length * 976}px`;

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 1
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else {
            controls.start("exit");
        }
    }, [controls, inView]);

    return (
        <Flex height={"auto"} flexDirection="column" maxWidth={"1440px"} alignItems="center" mt={20} width={widthValue}>
            <Heading color="#36E579" mb={14} textAlign="center" fontSize={{base:'lg',md:'5xl'}}>Our History</Heading>

            {/* 라인 */}
            <Box position="absolute"
                w={{ base: '1px', sm: '1px', md: "1px", lg: '1px' }}
                height={lineLength}
                bg="gray.300" left="50%"
                transform="translateX(-50%)"
                opacity={{ base: '0.2', sm: '0.3', md: "0.5", lg: '0.5' }} />

            <VStack spacing={6} align="start" width={widthValue} mx="auto">
                <List width={widthValue}>
                    {items.map((item, index) => {
                        return (
                            <motion.div ref={ref} animate={controls} variants={fadeIn} key={index} exit={fadeIn.done}>
                                <ListItem
                                    width={widthValue}
                                    position="relative"
                                    py={4}
                                    marginBottom="20px"
                                    display={'flex'}
                                    // justifyContent={index % 2 === 0 ? 'flex-start' : 'flex-end'}
                                    justifyContent="center"
                                    _before={{
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: '0',
                                        left: index % 2 === 0 ? '-15px' : 'calc(100% + 15px)',
                                        width: '75px',
                                        height: '38px',
                                    }}
                                >
                                    <Box maxW={itemMaxWidth}
                                        // textAlign={index % 2 === 0 ? 'left' : 'right'}
                                        textAlign="center"
                                    // py={14}
                                    >
                                        <Box as='a' href={item.href}>
                                            <Box as={Image} src={item.image} boxShadow={'2xl'} width={'auto'}></Box>
                                        </Box>

                                        <Heading fontSize={{ base: '16px', md: '36px' }} fontWeight="bold" color="gray.200" paddingY="14px" mt={{ base: '4', md: 8 }}>
                                            {item.title}
                                        </Heading>

                                        {/* <Text fontSize="16px" lineHeight="1.7" letterSpacing="0.01rem" color="gray.300" wordBreak="keep-all">
                                            {item.description}
                                        </Text> */}
                                        <Image src={item.description} alt="Section image" w="full" h={{ base: 'auto', md: '200px' }} objectFit={"scale-down"} />
                                    </Box>
                                </ListItem>
                            </motion.div>
                        );
                    })}
                </List>
            </VStack>
        </Flex>
    );
}