import React from 'react';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Layouts } from './Layout/layout';
import { Contents } from './components/Contents';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from './pages/Contact';
import Company from './pages/Company';
import Services from './pages/Services';
import XR from './pages/Xr';
import AR from './pages/Xr/Ar';
import VR from './pages/Xr/Vr';
import Workation from './pages/workation';

// 테마 설정
const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: 'gray.800',
        color: 'gray.50',
        heigth: '100%',
      },
    },
  },
  fonts: {
    heading: 'SUIT',
    body: 'SUIT',
  },
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layouts><Contents /></Layouts>} />
          <Route path='/Contact' element={<Layouts><Contact /></Layouts>} />
          <Route path='/Company' element={<Layouts><Company /></Layouts>} />
          <Route path='/Services' element={<Layouts><Services /></Layouts>} />
          <Route path='/Xr' element={<Layouts><XR /></Layouts>} />
          <Route path='/Xr/Ar' element={<Layouts><AR /></Layouts>} />
          <Route path='/Xr/Vr' element={<Layouts><VR /></Layouts>} />
          <Route path='/workation' element={<Workation />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;