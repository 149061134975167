import React from 'react';
import { Box, HStack, Icon, Image } from "@chakra-ui/react";
import { ArrowBackIcon } from '@chakra-ui/icons';

export default function workationHeader({ handleBack, activeStep  }) {
    return (
        <Box sx={{ padding: '19px 20px 19px 20px' }}>

            <HStack justifyContent={activeStep === 0 ? 'flex-start' : 'space-between'}>

                 {activeStep !== 0 && (
                    <Icon as={ArrowBackIcon} boxSize={8} color='blue.500' onClick={handleBack} />
                )}
                
                <Image src='OldandNewLogo.png' width={24} alt='올드앤뉴로고' />
            </HStack>
            {/* 올드앤 뉴 워케이션 */}
        </Box>
    )
} 