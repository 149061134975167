'use client'
import React, { useState } from "react";
import WorkationHeader from '../../components/WorkationHeader';
import PrivacyPolicy from "../../components/WorkationContent/PrivacyPolicy";
import UserInfoForm from "../../components/WorkationContent/UserInfoForm";
import Review from "../../components/WorkationContent/Review";
import useWorkationForm from '../../utills/useWorkationForm';

import {
    Container,
    Flex,
    Box,
    // Heading,
    // Text,
    // IconButton,
    Button,
    // VStack,
    // HStack,
    // Wrap,
    // WrapItem,
    // FormControl,
    // FormLabel,
    // Input,
    // InputGroup,
    // InputLeftElement,
    // Textarea,
    // Select,
    // Toast,
    useToast,
} from '@chakra-ui/react'

// import {
//     MdPhone,
//     MdEmail,
//     MdLocationOn,
//     // MdFacebook,
//     MdOutlineEmail,
// } from 'react-icons/md'
// import { BsPerson } from 'react-icons/bs'
// import { useNavigate } from "react-router-dom";


// import { FaInstagram, FaTwitter } from 'react-icons/fa'
// import { useNavigate } from "react-router-dom";

const Workation = () => {
    const {
        userData,
        applicationData,
        updateUserData,
        updateCompanyInfo, 
        updateApplicationData
    } = useWorkationForm();
    const toast = useToast();

    const [activeStep, setActiveStep] = React.useState(0);
    const [isAvailed, setIsAvailed] = useState(false)
    const steps = ['개인정보 제공 동의', '신청', '제출'];
    // const navigate = useNavigate();

    function stepContent(step: number) {
        switch (step) {
            case 0:
                return <PrivacyPolicy />
            case 1:
                return <UserInfoForm userData={userData} updateUserData={updateUserData} applicationData={applicationData} updateApplicationData={updateApplicationData} updateCompanyInfo={updateCompanyInfo} onVaildChange={setIsAvailed}/>
            case 2:
                return <Review userData={userData} applicationData={applicationData}/>
            default:
                throw new Error('해당 페이지는 없습니다.');
        }
    }

    // const setAllValid = () => {
    //     setIsAvailed(true);
    // };

    const handleNext = () => {
        // console.log(activeStep);
        // setActiveStep(activeStep + 1);
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            toast({
                title: '올드앤뉴 워케이션 참가신청',
                position: "top",
                status: 'success',
                duration: 1500,
                isClosable: true,
            })
            // setTimeout(() => navigate('/workation'), 1000);
            setTimeout(() => window.location.reload() , 1000);
        }
    }

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    
    console.log("userData", userData);
    console.log(applicationData);
    
    
    return (
        <Flex minH={'100vh'} sx={{
            '.inner': {
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                height: '100%',
                margin: '0', // 기본 마진 설정
                paddingBottom: '40px',
                width: '100%', // 모바일에는 100% 너비
                // '@media(min-width: 768px)': {
                //     width: '500px', // 768px 이상에서는 500px 너비
                // }
            }
        }}>
            <Container centerContent overflow="hidden" maxH={'max-content'} position="relative" width={'100%'}>
                <Box
                    className="inner"
                    bg="gray.100"
                    color="black"
                >

                    <WorkationHeader handleBack={handleBack} activeStep={activeStep}/>

                    {/* <WorkationForm /> */}
                
                    <React.Fragment>
                        {stepContent(activeStep)}
                    </React.Fragment>
                    <Button
                        colorScheme="blue"
                        rounded="initial"
                        size={{ base: 'md', md: 'lg' }}
                        fontSize={{ base: 'small', md: 'lg' }}
                        fontFamily={"SUIT"}
                        fontWeight="Bold"
                        // bg={"#8990A0"}
                        isDisabled={
                            activeStep === 2 && isAvailed ? true : false
                        }
                        onClick={handleNext}
                    >
                        {activeStep === 0 ? '동의' : activeStep >= 2 ? "완료" : "다음"}
                    </Button>
                </Box>
            </Container>
        </Flex >
    )
}

export default Workation;