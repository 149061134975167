import React from 'react';
import { Box, Icon, keyframes, chakra } from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';

// 위아래로 움직이는 애니메이션 키프레임
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% { transform: translateY(-10px); }
`;

const ScrollDownIndicator = () => {

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      animation={`${bounce} 2s infinite`}
      position="absolute"  // 절대 위치 지정
      bottom="0"  // 화면 아래쪽부터 20% 높이만큼 올라온 위치
      left="50%"
      right="50%" 
      zIndex={3}
      transform="translateX(-50%)"
      height={"auto"}
      >
      <chakra.span fontSize="xl" marginBottom={1} color={'gray.200'} >
        Scroll
      </chakra.span>
      <Icon
        as={TriangleDownIcon}
        color={'gray.200'}
        w={8}
        h={8}
      />
    </Box >
  );
};

export default ScrollDownIndicator;