import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface BannerProps {
  backgroundImage: string;
  title: string;
  subtitle: string;
  src: string
}

const BannerSection: React.FC<BannerProps> = ({ backgroundImage, title, subtitle, src }) => {
  const navigate = useNavigate();

  const handleClick = () =>{
    navigate(`${src}`);
  }

  return (
    <Flex width={'100%'} height={{ base: '150px', md: '500px' }} position="relative">
      <Box
        width={'100%'}
        height={'100%'}
        bgImage={`url('${backgroundImage}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        // bgAttachment="fixed"
        position="absolute"
      />
      <Box
        width={'100%'}
        height={'100%'}
        bg="rgba(0, 0, 0, 0.7)" // 여기서 투명도를 조절하여 이미지를 어둡게 만듭니다.
        position="absolute"
      />
      <Flex
        position="relative" // 이 Flex에 콘텐츠를 추가합니다.
        direction="column"
        align="center"
        justify="center"
        width={'100%'}
        height={'100%'}
        zIndex="1"
        color="white"
      >
        <Text
          fontSize={{ base: '24px', md: '40px', lg: '56px' }}
          fontWeight="bold"
          textAlign="center"
          mb={4}
        >
          {title}
        </Text>
        <Text
          fontSize={{ base: '16px', md: '24px', lg: '18px' }}
          textAlign="center"
          mb={8}
        >
          {subtitle}
        </Text>
        <Button
          size="lg"
          bg="teal.400"
          color="white"
          _hover={{ bg: 'teal.500' }}
          onClick={handleClick}
        >
          우리의 작업 보기
        </Button>
      </Flex>
    </Flex>
  );
};

export default BannerSection;