import React from "react";
import { Box, Heading, Text, Image, Flex, Button } from '@chakra-ui/react';
import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom';

function WorksSection() {
  const works = [
    {
      title: '도전 MZ 게임',
      subtitle: '도전 MZ 게임 !',
      image: '/Services/도전mz게임.webp',
      href: 'https://www.videoask.com/fp923kls2',
    },
    {
      title: '스타트업의 꿈',
      subtitle: '여행 VR',
      image: '/Services/관광스타트업.webp',
      href: 'https://www.trepick.com/event/itbsc-matterport',
    },
    {
      title: '카메라가 사랑한 인천',
      subtitle: '내나라 여행박람회에서 찾다',
      image: '/Services/카메라가사랑한.webp',
      href: 'https://www.trepick.com/event/naenara',
    },
    {
      title: '거대넝쿨의 습격',
      subtitle: '충북대학교에서 다시 일어서자',
      image: '/Services/거대넝쿨의습격.webp',
      href: 'https://www.trepick.com/event/giantvine',
    },
    {
      title: '관광 취업의꿈',
      subtitle: '인천관광 미니 잡페어에서 찾다',
      image: '/Services/관광취업의꿈.webp',
      href: 'https://www.trepick.com/event/mini-jobfair',
    },
    {
      title: '인천 개항장스마트관광 서비스 개발공모',
      subtitle: '도전하는 당신의 이야기',
      image: '/Services/도전하는당신.webp',
      href: 'https://www.trepick.com/event/gaehangjang',
    },
    {
      title: '인천관광 협업 프로젝트 공모',
      subtitle: '도전하는 당신의 이야기',
      image: '/Services/도전하는당신협업.webp',
      href: 'https://www.trepick.com/event/itbsc-contest',
    },
    {
      title: '블랙클로버',
      subtitle: '사람들은 클로버를보고 행복이나 행운을 떠올린다 ',
      image: '/Services/블랙클로버.webp',
      href: 'https://www.trepick.com/event/blackclover',
    },
    {
      title: '상상유니브와 함께 더위 극복',
      subtitle: '소름돋는 이곳에서 대탈출 !',
      image: '/Services/상상대탈출.webp',
      href: 'https://www.trepick.com/event/murderer',
    },
    {
      title: '서일 대탈출',
      subtitle: '드론 360도 비디오',
      image: '/Services/서일대탈출.webp',
      href: 'https://www.trepick.com/event/campus-escape',
    },
    {
      title: '위기 탈출 감빵생활',
      subtitle: '억울한 누명에서 벗어나기위한 대탈출 !',
      image: '/Services/위기탈출감빵생활.webp',
      href: 'https://www.trepick.com/event/prisonlife',
    },
    {
      title: '응답하라 덕성 2021',
      subtitle: '드론 360도 비디오',
      image: '/Services/응답하라덕성.webp',
      href: 'https://www.trepick.com/event/duksae',
    },
    {
      title: '제주항공과 함께 인천으로',
      subtitle: '인천 트래블 마켓 사전예약 이벤트',
      image: '/Services/제주항공과인천으로.webp',
      href: 'https://www.trepick.com/event/jejuair',
    },
    {
      title: '꿈같은 대학생활 캠퍼스라이프',
      subtitle: '드론 360도 비디오',
      image: '/Services/캠퍼스라이프.webp',
      href: 'https://www.trepick.com/event/campuslife',
    },
    {
      title: '캠퍼스라이프 (축제편)',
      subtitle: '드론 360도 비디오',
      image: '/Services/캠퍼스라이프축제편.webp',
      href: 'https://www.trepick.com/event/campuslife-festival',
    },
    {
      title: '상상유니브와 함께 일상탈출',
      subtitle: '코로나 블루를 극복하자',
      image: '/Services/상상유니브일상탈출.webp',
      href: 'https://www.trepick.com/event/covidblue',
    },
    {
      title: '그녀와의 갑작스런 데이트',
      subtitle: '드론 360도 비디오',
      image: '/Services/갑작스런데이트.webp',
      href: 'https://www.trepick.com/event/ic-easy',
    },
    {
      title: '송도국제회의복합지구',
      subtitle: '붐업프로젝트',
      image: '/Services/송도붐업프로젝트.webp',
      href: 'https://www.videoask.com/fdxxj9bt0',
    },
  ];

  return (
    <Flex justifyContent={'center'}>
      <Box bgColor="gray.800" color="white" p={8} maxW={'1440px'} w={'100%'}>
        <Heading color="#36E579" mb={10} textAlign="center">Our Portfolio</Heading>

        <Flex justify="space-between" wrap="wrap">
          {works.map((work, index) => (
            <Box
              key={index}
              w={{ base: "100%", md: "fit-content", lg: "30%" }}
              mb={12}
              bgColor="gray.800"
              cursor="pointer"
              position="relative"
              _hover={{
                _after: {
                  content: `""`, // 콘텐츠는 빈 문자열
                  position: "absolute", // 절대 위치 설정
                  top: 0,
                  left: 0,
                  // right: 0, // 오른쪽 0 추가
                  bottom: 0, // 아래쪽 0 추가
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // 투명도 설정
                  display: "flex", // Flexbox 설정
                  flexDirection: "column", // 세로 방향 정렬
                  justifyContent: "center", // 중앙 정렬
                  alignItems: "center", // 아이템 중앙 정렬
                  color: "white", // 텍스트 색상 설정
                  opacity: 1,
                  zIndex: 5, // z-index 설정
                },
                '.overlay-content': {
                  opacity: 1, // 호버 시 내용물의 투명도를 1로 변경
                }
              }}>

              <Link to={work.href}>
                <Image src={work.image} alt={work.title} width='420px' height='340px' objectFit="cover" />
                <Flex justifyContent={'center'} alignItems={'center'} direction={'column'} width={'auto'}>
                  {/* <Text fontSize="2xl" fontWeight="bold" color="gray.900">
                    {work.title}
                  </Text>

                  <Text fontSize="md" color="gray.700">{work.subtitle}</Text> */}
                  <Text
                    className="overlay-content"
                    position="absolute"
                    top="50%"
                    w="100%"
                    textAlign="center"
                    color="white"
                    transform="translateY(-50%)"
                    opacity={0}
                    zIndex="docked" // z-index overlay 설정
                    transition="opacity 0.5s" // 투명도 변경 시 애니메이션 효과
                  >
                    {work.title}
                  </Text>
                  <Button
                    className="overlay-content"
                    position="absolute"
                    top="60%" // 버튼 위치 설정
                    colorScheme="none"
                    variant="solid"
                    rounded={'full'}
                    border={'1px'}
                    opacity={0}
                    zIndex="docked" // z-index overlay 설정
                    transition="opacity 0.5s" // 투명도 변경 시 애니메이션 효과
                  >
                    더 보기
                  </Button>
                </Flex>
              </Link>
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
}

export default WorksSection;