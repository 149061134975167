import React from "react";
import { Box, Button, Container, Flex, Icon, Image, Stack } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { MdPlayCircleOutline } from "react-icons/md";

const MotionBox = motion(Box);

type StickySectionProps = {
    isReversed: boolean;
    image:string;
    href:string;
}

const StickySection = ({ isReversed, image, href }: StickySectionProps) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const imageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };
    // const PlayIcon = createIcon({
    //     displayName: 'PlayIcon',
    //     viewBox: '0 0 58 58',
    // })

    // const items = [
    //     { title: 'AR공간투어 ' }
    // ]
    return (
        <Container maxW="1440px" p="0">
            <Flex
                ref={ref}
                align="stretch"
                position="relative"
                direction={{ base: 'column', md: isReversed ? 'row-reverse' : 'row' }}
                mb={{ base: 0, md: 6 }}
                mt={{ base: 0, md: 6 }}>
                <MotionBox
                    h={{ base: "", md: "500px" }}
                    flex={{ base: "auto", md: 1 }}
                    // initial={inView ? "visible" : "hidden"}
                    animate={inView ? "visible" : "hidden"}
                    variants={imageVariants}
                    transition={{ duration: 1 }}
                    mb={{ base: 4, md: 0 }}>
                    <Box
                        as="figure"
                        position="relative"
                        h={{ base: "full", md: 'full' }}
                        w="full">
                        <img src={image} alt="Section image" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </Box>
                </MotionBox>

                {/* 열 */}
                <Stack
                    align="center"
                    spacing={{ base: 8, md: 10 }}
                    textAlign="center"
                    w="full"
                    justifyContent="center"
                    visibility={inView ? "visible" : "hidden"}
                    flex={{ base: "auto", md: 1 }}
                    // px={{ base: 4, md: 0 }}
                >
                    <Flex
                        position="relative"
                        justifyContent="center"
                        alignItems="center"
                        // px={{ base: 4, md: 0 }}
                        h={{ base: "275px", md: '500px' }}
                        w="full">
                        <Image src="/공간을 플레이하다.webp" alt="Section image" w="full" h="full" objectFit={"scale-down"} />
                        {/* <img src="/공간을 플레이하다.png" alt="Section image" style={{ width: "100%", height: "100%", objectFit: "cover" }} /> */}

                        <Stack
                            justifyContent="center" direction={"row"}
                            spacing={{ base: 4, sm: 6 }}
                            // px={{ base: 4, md: 0 }}
                            position={"absolute"}
                            bottom={0}
                            width="100%"
                            left="50%"
                            right="50%"
                            transform="translate(-50%, -50%)"
                            px={6}
                        >
                            <Button
                                as="a"
                                href="/Contact"
                                rounded="full"
                                size={{base:'sm', md:'lg'}}
                                fontSize={{base:'small', md:'lg'}}
                                fontFamily={"SUIT"}
                                fontWeight="Bold"
                                px={6}
                                bg="#01B96E"
                                _hover={{ bg: '#03E272' }}
                            >
                                제작 문의
                            </Button>
                            <Button
                                as="a"
                                href={href}
                                rounded="full"
                                size={{base:'sm', md:'lg'}}
                                fontSize={{base:'small', md:'lg'}}
                                fontFamily={"SUIT"}
                                fontWeight="Bold"
                                px={6}
                            >
                                <Icon as={MdPlayCircleOutline} w={8} h={8} pr={2} color="gray.700" />
                                영상 보러가기
                            </Button>
                        </Stack>
                    </Flex>
                    {/* <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}
                        position="relative"
                        _after={{
                            content: "''",
                            width: 'full',
                            height: '30%',
                            position: 'absolute',
                            bottom: 1,
                            left: 0,
                            bg: '#01E96B',
                            opacity: 0.2,
                            zIndex: -1,
                        }}
                    >
                        {title}
                    </Heading>
                    <Text as="span" color="gray.200">
                        {subtitle}
                    </Text>
                    <Text color="gray.300" px={{ base: 0, md: 32 }} fontSize={{ base: 'xs', md: 'medium' }}>
                        {description}
                    </Text> */}
                </Stack>
            </Flex>
        </Container>
    )
}

export default StickySection;
