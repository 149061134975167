'use client'
import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Button,
  Image,
} from '@chakra-ui/react'

import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
  bgColor?: string
}
interface DesktopNavProps {
  isScrolled: boolean;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'XR',
    children: [
      {
        label: 'AR',
        subLabel: '증강현실 콘텐츠를 오르비터에서 ',
        href: '/Xr/Ar',
      },
      {
        label: 'VR',
        subLabel: '가상 공간 & 가상의 월드 인프라 구축 및 제작',
        href: '/Xr/Vr',
      },
    ],
  },
  {
    label: 'Company',
    href: '/Company',
  },
  {
    label: 'Services',
    href: '/Services',
  },
]


const DesktopNav = ({ isScrolled }: DesktopNavProps) => {
  const linkColor = useColorModeValue(isScrolled ? 'white' : 'gray.800', 'gray.200');
  // const linkHoverColor = useColorModeValue('white', 'gray.800');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  // const navigate = useNavigate(); // 현재 경로 파악
  

  return (
    <Stack direction={'row'} spacing={'36'} justifyContent={"center"} alignItems={"center"} zIndex={10} mr={'32'} right={'20'}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>

              {/* 메뉴부분 */}
              <Box
                as='a'
                p={2}
                href={navItem.href}
                fontSize={'lg'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: '#01E96B',
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {/* 하위메뉴? */}
            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  // const router = useRouter(); //현재경로

  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('green.50', 'gray.900') }}
      style={{
        backgroundColor:'rgba(1, 233, 107, 0.2)', // 현재 경로와 일치하는지에 따라 배경색을 설정합니다.
      }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: '#01E96B' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        {/* 토글 메뉴옆 아이콘 */}
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'#01E96B'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}


// 모바일 메뉴
const MobileNav = () => {
  // const [headerHeight, setHeaderHeight] = useState('80px'); // 헤더 높이 상태
  const headerHeight = '80px';
  const [bgColor, setBgColor] = useState('white'); // 배경색 상태
  // const { isOpen } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const newBgColor = window.scrollY > 30 ? 'gray.800' : 'white';
      setBgColor(newBgColor);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Stack bg={bgColor} display={{ md: 'none' }} top={headerHeight} position={"fixed"} zIndex={10} width={"100%"}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} bgColor={bgColor} />
      ))}
    </Stack>
  )
}

// 모바일 하위메뉴
const MobileNavItem = ({ label, children, href, bgColor }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()
  // const [scrolled, setScrolled] = useState(false);

  const textColor = bgColor === 'gray.800' ? 'gray.200' : 'gray.800' ;

  return (
    <Stack spacing={4} onClick={children && onToggle} >
      <Box
        py={2}
        as="a"
        pl={4}
        href={href}
        justifyContent="space-between"
        alignItems="center"
        color={textColor}
        _hover={{
          textDecoration: 'none',
          color: "#01E96B"
        }}>
        <Text fontWeight={600} color={textColor}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={10}
            h={'full'}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity
      >
        {/* 하위의 하위메뉴? */}
        <Stack
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.800')}
          color= {textColor}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}


// Pc
export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const [scrolled, setScrolled] = useState(false);

  const iconColor = scrolled ? 'white' : 'black';

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  return (
    <Box>
      <Flex
        bg={scrolled ? useColorModeValue('gray.800', 'gray.900') : useColorModeValue('white', 'transparent')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'80px'}
        position="fixed"
        w="100%"
        zIndex={5}
        justifyContent={'center'}
        transition="background-color 0.3s ease"
      >

        {/* 1열 */}
        <Flex
          flex={{ base: 0.3, md: 'none' }}
          py={4}
          justifyContent="center"
          display={{ base: 'center', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={6} h={6} /> : <HamburgerIcon w={8} h={8} />}
            variant={'ghost'}
            color={iconColor}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        {/* 2열 */}
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'space-between' }} maxW={'1440px'} alignItems={"center"}>
          <Link to="/">
            <Image src={scrolled ? ("/logo_orbitor_horizontal_green+white.webp") : ("/logo_orbitor_horizontal_green+black.webp")}
              width={180} height={"auto"} sx={{ ml: 5}}
              alt='오르비터_로고img' />
          </Link>
          <Flex display={{ base: 'none', md: 'flex' }}>
            <DesktopNav isScrolled={scrolled} />
          </Flex>
        </Flex>

        {/* 3열 */}
        <Flex >
          <Link to='/Contact'>
            <Button
              size="md"
              position="relative"
              rounded={0}
              height="80px"
              color={'gray.800'}
              width={{ base: "100px", md: "180px" }} // 모바일에서는 100%, 데스크탑에서는 180px
              bg={'#01E96B'}
              _hover={{
                bg: '#03E872',
              }}>
              문의
            </Button>
          </Link>
        </Flex>


        <Stack
          flex={{ base: 'none', md: 'none' }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
        </Stack>
      </Flex>

      {/* 여기에 모바일 네비게이션 컴포넌트가 있을 수 있습니다 */}
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box >


  )
}

