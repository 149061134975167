'use client'
import React from "react";
import {
    Box,
    // Heading,
    Image,
    // Text,
    // Divider,
    // HStack,
    // Tag,
    // Wrap,
    // WrapItem,
    // SpaceProps,
    // useColorModeValue,
    Container,
    // VStack,
    // ListItem,
    // List,
} from '@chakra-ui/react'
// import { color } from 'framer-motion'
// import { title } from 'process'

// interface Props {
//     marginTop?: number
//     tags: any[]
// }

const items = [
    {
        title: 'Product',
        src: '/오시아노리조트.webp',
        image: '/오르비터_스티키섹션_오시아노 리조트.webp',
        slogan: '오시아노 리조트 호텔',
        explanation: '땅끝 해날에 들어서는 무장애, 친환경 호텔',
    }, 
    {
        title: 'Product',
        src: '/서울관광기업지원센터.webp',
        image: '/오르비터_스티키섹션_서울관광기업지원센터.webp',
        slogan: '서울관광 기업지원센터',
        explanation: '(한국관광공사)증축 및 리모델링 한류화 관광기업 시설',
    },
    {
        title: 'Product',
        src: '/대불호텔.webp',
        image: '/오르비터_스티키섹션_대불호텔.webp',
        slogan: '대불호텔',
        explanation: '최초의 서구식 호텔로 개항기 시대의 유산',
    },
    {
        title: 'Product',
        src: '/스테이폴리오.webp',
        image: '/오르비터_스티키섹션_스테이폴리오.webp',
        slogan: '스테이폴리오',
        explanation: '유니크한 숙박 큐레이팅 플랫폼 스테이폴리오 숙박시설 3D 공간투어 제작',
    },
]

const SosokSticky = () => {
    // const color = useColorModeValue('gray.50', 'gray.200');

    return (
        <Container maxW={'1440px'} w={{base:"100%", md:'auto'}} justifyContent={'center'} alignItems={'center'} py={{base:'6', md:'40'}}>
            {items.map((item, index) => (
                <Box
                    // marginTop={{ base: '1', sm: '5' }}
                    key={index}
                    display="flex"
                    flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'}
                    alignItems={'stretch'}
                    position={'sticky'}
                    top={0}
                    h={{ base: "", md: "auto" }}
                    justifyContent="space-between">


                    {/* 이미지 부분 */}
                    <Box
                        display="flex"
                        flex="1"
                        position="relative"
                        // w={"calc(50vw)"}
                        maxW="50%"
                        height="100%"
                        objectFit="cover"
                        alignItems="center"
                        // p={(index % 2) === 1 ? "0 12px 0 0" : "0 0 0 12px"}
                    >
                        <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                // borderRadius="lg"
                                src={item.src}
                                alt="some good alt text"
                                objectFit="contain"
                            />
                        </Box>
                    </Box>

                    {/* 텍스트 부분 */}
                    <Box
                        display="flex"
                        flex="1"
                        maxW="100%"
                        flexDirection={"column"}
                        justifyContent="center"
                        bg={"gray.800"}
                        // p={(index % 2) === 1 ? "0" : "3 0"}
                        boxSizing='border-box'>
                            <Image src={item.image} alt="Section image" w="full" h="full" objectFit={"scale-down"} />

                        {/* <Heading as="h1" fontSize="lg" color={'#01E96B'} justifyContent={'center'}>{item.title}</Heading> */}
                        {/* <BlogTags tags={['Engineering', 'Product']} /> */}
                        {/* <Heading marginTop="1">
                            <Text textDecoration="none" _hover={{ textDecoration: 'none' }} fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}>
                                {item.slogan}
                            </Text>
                        </Heading>
                        <Text
                            as="p"
                            marginTop="2"
                            color={color}
                            fontSize={{base:'sm',sm:'sm', lg:'lg'}}
                        > 
                            {item.explanation}
                        </Text> */}
                    </Box>
                </Box>
            ))}
        </Container>
    )
}

export default SosokSticky