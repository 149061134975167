import { AspectRatio, Box, Container, Divider, Image, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

export function YoutubOrbitor() {

    const youtubeVideos = [
        {
            title: '/오르비터유튜브01.webp',
            src: 'https://www.youtube.com/embed/VBBmVW9mXpw?si=QBg-IfIcsHw7zMdI',
            description: '/오르비터유튜브_des_01.webp'
        },
        {
            title: '/오르비터유튜브02.webp',
            src: 'https://www.youtube.com/embed/ZD2jr1Y_J_U?si=gnSE8jk8dkwLdFK',
            description: '/오르비터유튜브_des_02.webp'
        },
        {
            title: '/오르비터유튜브03.webp',
            src: 'https://www.youtube.com/embed/YNndxbPJUMY?si=haWeDo6xlbsADQgc',
            description: '/오르비터유튜브_des_03.webp'
        }
    ];

    return (
        <Container maxW={'1440px'} py={{ base: 4, md: 14 }}>
            <SimpleGrid columns={{ base: 1, lg: 1 }} spacing={{ base: "none", md: 10 }}>
                {youtubeVideos.map((video, index) => (
                    <Box key={index} display="flex" flexDirection="column" alignItems="center" py={{base:4, md:10}} >
                        <AspectRatio maxW="1050px" ratio={16 / 9} width="100%">
                            <iframe
                                title={video.title}
                                src={video.src}
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                        </AspectRatio>
                        <Box mt={{ base: '' }} width="70%" textAlign="center">
                            <Image src={video.title} alt="Section image" w="full" h={{ base: 'auto', md: '200px' }} objectFit={"scale-down"} />
                            <Divider />
                            <Image src={video.description} alt="Section image" w="full" h={{ base: 'auto', md: '200px' }} objectFit={"scale-down"} />
                        </Box>
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    );
}

