import { Box, Card, CardBody, CardHeader, Divider, FormControl, FormHelperText, Heading, Text } from "@chakra-ui/react";
import React from "react";

const Review = ({ userData, applicationData }:any) => {

    return (
        <Box sx={{ mt: 4, mb: 4, p: 4, overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', bgcolor: '#f9f9f9' }} >
            {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}> */}
            <Text textAlign="center" fontSize="lg" sx={{ pb: 4 }}>
                {userData.name} 님의 워케이션 신청정보를 확인해 주세요.
            </Text>
            {/* </Typography> */}

            <FormControl>
                <Box textAlign="center" sx={{ my: 2 }} >
                    {/* <Typography sx={{ fontSize: '0.7em' }}> */}
                    <Text fontSize="xs">
                        아래의 진행절차에 따라 신청확정까지 다소 시간이 걸릴 수 있습니다.
                    </Text>
                </Box>

                <Divider />


                <Card variant='outline' m={4}>
                    <CardHeader>
                        <Heading size='md'>{applicationData.package}</Heading>
                    </CardHeader>
                    <CardBody>
                        
                        {`체크인${applicationData.checkIn} 체크아웃${applicationData.checkOut}`}
                    </CardBody>
                </Card>

                <FormHelperText color="green.500">신청정보가 맞으시면 `&quot; 완료 `&quot; 버튼을 눌러주세요</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default Review;