'use client'
import React from "react";
import {
    Heading,
    Box,
    Text,
    Image,
    VStack,
    Flex,
    Button,
    // background,
} from '@chakra-ui/react'

interface MyProps {
    src: string;
    title: string;
    description: string;
}

const MyCardProps = [
    {
        src: '/VR_ICON001.webp',
        title: 'VR콘텐츠',
        description: '8K 실사 VR콘텐츠 제작 관광/문화유산 VR기록 항공VR 투어'
    },
    {
        src: '/VR_ICON002.webp',
        title: 'VR / AR 마케팅',
        description: 'Web VR/AR 사이트 개발 구글 스트리트뷰 제작 VR체험존 구축'
    },
    {
        src: '/VR_ICON003.webp',
        title: 'VR 플랫폼',
        description: 'VR 영상콘텐츠 플랫폼 서비스 여행 VR유튜브 채널 운영 360 스톡 콘텐츠 판매'
    },
]

export const MyCard: React.FC<MyProps> = ({ src, title, description }) => {
    return (
        <Box
            borderRadius="lg"
            boxShadow="lg"
            bg="gray.200"
            w={{base:'auto', md:'375px'}}
            h={{base:'auto', md:'500px'}}
            p={8}
            textAlign="center"
        >
            <VStack spacing={4} pt={14}>
                <Image
                    boxSize="100px"
                    src={src}
                    alt="Card List"
                />
                <Heading size="xl!important" color="gray.800" pt={5}>
                    {title}
                </Heading>
            </VStack>
            <Text 
            fontSize="md" color="gray.800" pt={10}>
                {description}
            </Text>
            <Button
                as="a"
                href='/Contact'
                color='gray.100' 
                backgroundColor="gray.800"
                width={150}
                mt={30}
                _hover={{
                    color:'#01E96B',
                    scale:1.5,
                    backgroundColor:'gray.700'
                }}
            >
                제작 문의
            </Button>
        </Box>
    )
}

export const MyCardList = () => {
    return (
        <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gap={12}
            p={8}
            my={12}
            direction={'row'}
            wrap={'wrap'}
            maxW={'1440px'}
            w={'100%'}
        >
            {MyCardProps.map((cardProps, index) => (
                <MyCard key={index} {...cardProps} />
            ))}
        </Flex>
    )
}