'use client'
import React, { useState } from "react";
import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react'
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from 'react-icons/md'
import {  BsPerson } from 'react-icons/bs'
import { FaInstagram, FaTwitter} from 'react-icons/fa'
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const [information, setInformation] = useState({
    name:'',
    email:'',
    phone:'',
    inquiry:'',
  });
  const navigate = useNavigate();
  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setInformation(prev => ({
      ...prev,
      [name] : value
    }));
  }
  
  const hnadlesubmit = () => {
    
    alert(`문의 주셔서 감사합니다.${information.name}님\n 빠른 시일 내 답변드리겠습니다.`);

    navigate('/');
  }

  return (
    <Flex height={"full"}>
      <Container bg="gray.800" maxW="full" centerContent overflow="hidden" maxH={'fit-content'} mt={24}  position="relative">
        <Box
          bg="gray.100"
          color="black"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          mt={{sm:44,md:40,lg:4}}
          mb={{sm:32,md:40,lg:4}}
          px={{ sm: 5, md: 5, lg: 16 }}>
          <Box py={{base:10, md:20}}>

            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 12 }}>
              <WrapItem>
                <Box>
                  <Heading>문의하기</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                    메일 또는 전화 카카오톡채널로도 상담가능합니다.
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      <Button
                        as="a"
                        href='tel:01098875006'
                        size="md"
                        height="48px"
                        width="auto"
                        variant="ghost"
                        color="black"
                        _hover={{ border: '2px solid #01E96B' }}
                        leftIcon={<MdPhone color="#01E96B" size="20px" />}>
                        +82 10-9887-5006
                      </Button>
                      <Button
                        as="a"
                        href='mailto:myslover@naver.com'
                        size="md"
                        height="48px"
                        width="auto"
                        variant="ghost"
                        color="black"
                        _hover={{ border: '2px solid #01E96B' }}
                        leftIcon={<MdEmail color="#01E96B" size="20px" />}>
                        myslover@naver.com
                      </Button>
                      <Button
                        as="a"
                        href='https://naver.me/FS6ed1Oe'
                        size="md"
                        height="48px"
                        width="auto"
                        variant="ghost"
                        color="black"
                        _hover={{ border: '2px solid #01E96B' }}
                        leftIcon={<MdLocationOn color="#01E96B" size="20px" />}>
                        인천광역시 연수구 송도과학로 70<br />
                        업무동 1306호
                      </Button>
                    </VStack>
                  </Box>

                  <HStack
                    mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    // px={5}
                    alignItems="flex-start">
                    <IconButton
                      aria-label="facebook"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#E0E1E7' }}
                      icon={<MdFacebook size="28px" />}
                    />
                    <IconButton
                      aria-label="github"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#E0E1E7' }}
                      icon={<FaInstagram size="28px" />}
                    />
                    <IconButton
                      aria-label="discord"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#E0E1E7' }}
                      icon={<FaTwitter size="28px" />}
                    />
                  </HStack>
                </Box>
              </WrapItem>
              
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={16} color="#0B0E3F">
                    <VStack spacing={5}>

                      <FormControl id="name">
                        <FormLabel>이름</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none" >
                            <BsPerson color="01E96B" />
                          </InputLeftElement>
                          <Input type="text" size="md" name="name" value={information.name} onChange={handleInputChange}/>
                        </InputGroup>
                      </FormControl>

                      <FormControl id="name">
                        <FormLabel>메일</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineEmail color="01E96B" />
                          </InputLeftElement>
                          <Input type="text" size="md" name="email" value={information.email} onChange={handleInputChange}/>
                        </InputGroup>
                      </FormControl>

                      <FormControl id="phone">
                        <FormLabel>전화번호</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <MdPhone color="01E96B" />
                          </InputLeftElement>
                          <Input type="number" size="md" name="phone" value={information.phone} onChange={handleInputChange}/>
                        </InputGroup>
                      </FormControl>

                      <FormControl id="name">
                        <FormLabel>문의내용</FormLabel>
                        <Textarea
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: 'gray.300',
                          }}
                          placeholder="message"
                          name="inquiry"
                          value={information.inquiry}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      <FormControl id="submit" float="right">
                        <Button variant="solid" bg="#01E96B" color="white" _hover={{}} onClick={hnadlesubmit}>
                          보내기
                        </Button>
                      </FormControl>

                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Container>
    </Flex>
  )
}