import React, { useEffect, useRef, useState } from "react"
import { motion } from 'framer-motion';
import { Box, Card, CardBody, CardFooter, CardHeader,  Divider, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Image, Input, InputGroup, List, ListItem, Select, Stack, Text, useRadio, useRadioGroup } from "@chakra-ui/react"

// 상품선택 컴포넌트
function RadioCard(props: any) {
    const { getInputProps, getRadioProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label' >
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                fontWeight={'bold'}
                fontSize={{ base: "small", md: "medium" }}
                _checked={{
                    bg: 'blue.300',
                    color: 'white',
                }}
                _focus={{
                    boxShadow: 'filled',
                }}
                px={3}
                py={2}
            >
                {props.children}
            </Box>
        </Box>
    )
}

interface Company {
    title: string,
    category: string,
    roadAddress: string,
    description: string
}

const UserInfoForm = ({ userData, updateUserData, applicationData, updateApplicationData, updateCompanyInfo }: any) => {
    const [showPhoneInput, setShowPhoneInput] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(false);
    const phoneInputRef = useRef(null);
    const emailInputRef = useRef(null);

    const [query, setQuery] = useState('');
    const [results, setResults] = useState<Company[]>([]);
    const [selectedResult, setSelectedResult] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const [phoneError, setPhoneError] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);
        // if (value.length > 1) {
        //     setShowResults(true);
        // } else {
        //     setShowResults(false);
        // }
        if (value.length < 1) {
            setShowResults(false);
        }
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleSearch(query);  // 'Enter' 키를 눌렀을 때 검색 실행
            setShowResults(true);
        }
    }
    const handleSelectCompany = (company: any) => {
        setSelectedResult(true);

        const cleanTitle = company.title.replace(/<\/?b>/g, '');
        updateCompanyInfo('companyName', cleanTitle);
        updateCompanyInfo('companyAddress', company.roadAddress);
        updateCompanyInfo('category', company.category);
        setShowResults(false);
    }

    const handleSearch = async (query: string) => {
        if (query.length > 1) {
            const url = `/api/searchCompany?query=${encodeURIComponent(query)}`;
            try {
                const response = await fetch(url)
                if (response.ok) {
                    const data = await response.json();
                    setResults(data.items);
                } else {
                    throw new Error('결과값불러오기 실패 ');
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
                setResults([]);
            }
        } else {
            setResults([]);
        }
    }

    const validatePhone = (phone: any) => {
        const cleanPhone = phone.replace(/-/g, ''); // 모든 '-' 제거
        if (cleanPhone.length === 0) {
            setPhoneError('');
            setShowEmailInput(false);
            setIsValid(false);
            return;
        }

        if (cleanPhone.length > 0 && cleanPhone.length < 11) {
            setPhoneError('휴대폰 번호는 최소 11자리 이상이어야 합니다.');
            setIsValid(false);
            setShowEmailInput(false);
        } else if (!/^\d+$/.test(cleanPhone)) {
            setPhoneError('"-"를 제외한 숫자만 입력해주세요.');
            setIsValid(false);
            setShowEmailInput(false);
        } else {
            setPhoneError('');
            setIsValid(true);
            setShowEmailInput(true);
        }

    };

    useEffect(() => {
        if (!userData.name) {
            setShowPhoneInput(false);
        } else {
            const nameLength = userData.name.length;
            if (nameLength >= 3) {
                setShowPhoneInput(true);
            }
        }
        validatePhone(userData.phone);
    }, [userData])

    const inputVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5 } }
    }

    const carrier = ['SKT', 'LG U+', 'KT'];

    const options = [
        { id: 1, package: '역사체험형', price: '42,900', description: '역사적인 장소를 방문하며 배우는 여행.', img: "https://s3.ap-northeast-2.amazonaws.com/orbitor.site/%EA%B0%9C%ED%95%AD%EC%9E%A5_%EC%82%AC%EC%A7%842.jpg" },
        { id: 2, package: '해양휴양형', price: '59,000', description: '바다와 함께하는 휴양 여행.', img: "https://s3.ap-northeast-2.amazonaws.com/orbitor.site/%EC%98%81%EC%A2%85%EB%8F%84_%EC%82%AC%EC%A7%842.jpg" },
        { id: 3, package: '야간관광형', price: '34,200', description: '밤의 도시를 누비는 관광.', img: "https://s3.ap-northeast-2.amazonaws.com/orbitor.site/%EC%86%A1%EB%8F%84_%EC%82%AC%EC%A7%841.jpg" }
    ];

    const [selectedPackage, setSelectedPackage] = useState(options[0]);
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'package',
        defaultValue: selectedPackage.package,
        onChange: (value) => {
            const newSelectedPackage = options.find(option => option.package === value);
            if (newSelectedPackage) {
                setSelectedPackage(newSelectedPackage);
            }
        },
    });

    const group = getRootProps();

    useEffect(() => {
        updateApplicationData({
            package: selectedPackage.package,
            price: selectedPackage.price,
        })
    }, [selectedPackage])


    // console.log("selectedResult", selectedResult);

    return (
        <Stack spacing='4' py={4} px={4}>
            <Card variant='outline'>
                <CardHeader>
                    <Heading size='md'>신청자 정보</Heading>
                </CardHeader>
                <CardBody>
                    <Text fontWeight={'bold'} sx={{ px: 2, pb: 3 }}> 이름</Text>
                    <Input
                        placeholder="이름을 입력해주세요"
                        value={userData.name}
                        onChange={e => updateUserData('name', e.target.value)}
                    />
                </CardBody>
                {showPhoneInput && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={inputVariants}
                    >
                        <CardBody>
                            <FormControl isInvalid={!!phoneError}>
                                <FormLabel>휴대폰번호</FormLabel>
                                <Flex gap={4}>
                                    <Box width="30%">
                                        <Select placeholder="통신사 선택">
                                            {carrier.map((data, index) => (
                                                <option key={index} value={data}>{data}</option>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Box width="70%">
                                        <Input
                                            type="text"  // type을 number에서 text로 변경하였습니다. (국제 번호 포함 시 문자도 입력될 수 있도록)
                                            value={userData.phone}
                                            ref={phoneInputRef}
                                            placeholder="휴대폰 번호를 입력해주세요."
                                            onChange={e => updateUserData('phone', e.target.value)}
                                        />
                                    </Box>
                                </Flex>
                                {isValid ? (
                                    <FormHelperText color="green.500"></FormHelperText>
                                ) : (
                                    phoneError ? (
                                        <FormErrorMessage>{phoneError}</FormErrorMessage>
                                    ) : (
                                        <FormHelperText>&quot;-&quot;를 제외한 숫자만 입력해주세요.</FormHelperText>
                                    )
                                )}
                            </FormControl>
                        </CardBody>
                    </motion.div>
                )}

                {showEmailInput && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={inputVariants}
                    >
                        <CardBody>
                            <Text fontWeight={'bold'} sx={{ px: 2, pb: 3 }}> 이메일</Text>
                            <Input
                                placeholder="이메일을 입력해 주세요."
                                value={userData.email}
                                ref={emailInputRef}
                                onChange={e => updateUserData('email', e.target.value)}
                            />
                        </CardBody>
                    </motion.div>
                )}
            </Card>

            <Card variant='outline'>
                <CardHeader>
                    <Heading size='md'>직장 정보</Heading>
                </CardHeader>
                <CardBody>
                    <InputGroup>
                        <Input
                            type="text"
                            value={query}
                            onChange={handleQueryChange}
                            onKeyPress={handleKeyPress}
                            variant='flushed'
                            placeholder="다니시는 기업 또는 직장 이름을 입력 후 Enter 를 눌러주세요"
                        // onBlur={() => setShowResults(false)}
                        // onFocus={() => results.length > 0 && setShowResults(true)}
                        />
                    </InputGroup>
                    {showResults && (
                        <List spacing={1} mt={2} boxShadow="md" bg="white" zIndex="dropdown">
                            {results.map((item, index) => (
                                <ListItem key={index} p={2} cursor="pointer" _hover={{ bg: 'gray.100' }} onClick={() => handleSelectCompany(item)} >
                                    <Text fontWeight={'bold'} pointerEvents="none">
                                        {item.title.replace(/<\/?b>/g, '')}
                                    </Text>
                                    <Text fontSize={"smaller"} pointerEvents="none">
                                        {item.roadAddress}
                                    </Text>
                                    <Text fontSize={"xx-small"} pointerEvents="none">
                                        {item.category}
                                    </Text>
                                </ListItem>
                            ))}
                        </List>
                    )}

                </CardBody>
                {/* {selectedResult && <div>선택된 결과: {selectedResult}</div>} */}
            </Card>

            {selectedResult && (
                <Card>
                    <CardBody>
                        <Box gap={2} mb={4}>
                            <Input variant="unstyled" color={"gray"} readOnly disabled value={userData.companyInfo.companyName} />
                        </Box>
                        <Input variant='filled' color={"gray"} readOnly disabled value={userData.companyInfo.companyAddress} />
                    </CardBody>
                </Card>
            )}

            <Card>
                <CardBody>
                    <Image
                        src={selectedPackage.img}
                        alt={selectedPackage.package}
                        borderRadius='lg'
                    />
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Stack mt='6' spacing='2'>
                            <Heading size='md'>{selectedPackage.package}</Heading>
                            <Text fontSize={"small"}>
                                {selectedPackage.description}
                            </Text>
                        </Stack>

                        <Text color='blue.600' fontSize='2xl' alignContent={"end"}>
                            {selectedPackage.price}원
                        </Text>
                    </Box>

                </CardBody>

                <Divider />

                <CardFooter
                    {...group}
                    gap={4}
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems="center">
                    {options.map((option) => {
                        const radio = getRadioProps({ value: option.package })
                        return (
                            <RadioCard key={option.id} {...radio} >
                                {option.package}
                            </RadioCard>
                        )
                    })}
                </CardFooter>
            </Card>

            <Card>

                <CardHeader>
                    <Heading size='md'>예약</Heading>
                </CardHeader>

                <CardBody>
                    <Flex justifyContent={"space-between"}>
                        <Box width="48%">
                            <FormControl>
                                <FormLabel htmlFor="checkin-date">체크인</FormLabel>
                                <Input
                                    id="checkin-date"
                                    type="date"
                                    value={applicationData.checkIn || ''}
                                    onChange={(e) => updateApplicationData({
                                        checkIn: e.target.value
                                    })}
                                    placeholder="Check-in date"
                                />
                            </FormControl>

                        </Box>
                        <Box width="48%">
                            <FormControl>
                                <FormLabel htmlFor="checkout-date">체크아웃</FormLabel>
                                <Input
                                    id="checkout-date"
                                    type="date"
                                    value={applicationData.checkOut || ''}
                                    onChange={(e) => updateApplicationData({
                                        checkOut: e.target.value
                                    })}
                                    placeholder="Check-out date"
                                />
                            </FormControl>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
        </Stack>
    )
}

export default UserInfoForm;