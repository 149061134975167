import { ButtonContainer } from '../../../components/MainButton';
import Product from '../../../components/Product';
import ScrollDownIndicator from '../../../components/ScrolledDownIndicator';
import { Box, Image} from '@chakra-ui/react';
import React from 'react';

export default function AR() {
    const products = [
        {
            id: 1,
            name: '확장현실(XR) 투어 ',
            subname: '오르비터제작',
            embedurl: 'https://mpembed.com/show/?m=BNThQKLYFhw',
            description: '/VR_가상공간투어_description.webp',
            src:'',
        }
    ];

    return (
        <Box>
            <Box w={'atuo'} height={"90vh"} position={"relative"} overflow={"hidden"} justifyContent={'center'} alignItems={'center'} display='flex' flexDirection={"column"}>
                <Box width={{ base: 'full', sm: 'lg', lg: '2xl' }} display="flex" flexDirection="column" >

                    <Image
                        src='/Herobackground/Hero_title_AR.webp'
                        alt="hero image"
                        style={{
                            width: "400px",
                            height: "200px",
                            objectFit: "fill",
                            position: "relative",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: '-1'
                        }}
                    />
                    <Box position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
                        zIndex={'-2'}>
                    </Box>
                    {/* <chakra.h3
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        fontSize={20}
                        pt={24}
                        textTransform={'uppercase'}
                        textAlign={'center'}
                        color={'#01E96B'}>
                        one step towards the future
                    </chakra.h3>
                    <chakra.h1
                        py={5}
                        fontSize={48}
                        fontFamily={'Work Sans'}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        color="gray.300">
                        AR
                    </chakra.h1>
                    <chakra.h2
                        margin={'auto'}
                        width={'70%'}
                        fontFamily={'Inter'}
                        textAlign={'center'}
                        fontWeight={'medium'}
                        color='gray.400'>
                        가상현실과 증강현실 기술을 활용하여 새로운 차원의 경험을 만들어내는 과정은 창의적이고 상세한 설명을 필요로 합니다. XR기술을 활용한(확장현실)을 오르비에서 만나보세요!
                    </chakra.h2> */}
                    {/* 스크롤 가이더 */}
                    <ButtonContainer />
                    <ScrollDownIndicator />
                </Box>

                {/*영상 관련부분  */}
                <Box position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(0, 0, 0, 0.6)"  // 50% 투명도의 검은색 오버레이
                    zIndex={'-2'}></Box>
                <Image
                    src='/bg_Ar_Hero.jpg'
                    alt="hero image"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: '-3'
                    }}
                />
            </Box>

            {/* 2번섹션 */}
            {products.map(product => (
                <Product key={product.id} name={product.name} subname={product.subname} embedurl={product.embedurl} description={product.description} src={product.src}/>
            ))}

        </Box >
    );
}
